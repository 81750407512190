import Navbar from "../components/navbar8";
import Mentor from "../components/mentor_hero";
import Footer from "../components/footer4";
import { Helmet } from "react-helmet";

const Mentors = ()  => {
  return (
    <div className="Mentors">
       <Helmet>
        <title>FlarEdge | Mentors </title>
        <meta name="description" content="FlarEdge provides personalized mentorship and career advice." />
        <link rel="canonical" href="https://flaredge.online/Mentors" />
      </Helmet>
   < Navbar/>
   <Mentor/>
    <Footer/>
       
    </div>
  );
}

export default Mentors;