import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore'; // Use setDoc and doc for creating a custom document ID
import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/navbar8';
import { auth, db } from '../firebase';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setuserName] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      // Create a new profile in Firestore with a custom userId
      await setDoc(doc(db, "Mentors", userId), {
        userId: userId,
        username: username,
        name: '',
        description: '',
        domains: [],
        city: '',
        pincode: 0,
        gender: '',
        instagram: '',
        youtube: '',
        linkedin: '',
        profileimage: '',
        images: [4]

      });

      // Redirect to the newly created user's profile page
      navigate(`/Mentors/${userId}`);
      alert("Registration successful! Please log in.");
    } catch (err) {
      setError(err.message);
      console.error("Error creating user:", err);
    }
  };

  return (
    <>
      <Helmet>
        <title>FlarEdge | Signup</title>
        <meta name="description" content="FlarEdge provides personalized mentorship and career advice." />
      </Helmet>
      <Navbar />
      <section className="h-100 bg-dark">
        <div className="container py-5 h-100">
          <form className="row d-flex justify-content-center align-items-center h-100" onSubmit={handleSignup}>
            <div className="col-xl-6 d-none d-xl-block">
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/img4.webp"
                alt="Sample img"
                className="img-fluid"
                style={{ borderTopLeftRadius: '.25rem', borderBottomLeftRadius: '.25rem' }}
              />
            </div>
            <div className="col-xl-6">
              <div className="card card-registration my-4">
                <div className="card-body p-md-5 text-black">
                  <h2 className="mb-3 text-start">Create Your Account</h2>
                  {error && <p className="text-danger">{error}</p>}
                  <input
                    type="text"
                    className="form-control form-control-lg mb-3"
                    placeholder="Username*"
                    value={username}
                    onChange={(e) => setuserName(e.target.value)}
                    required
                  />
                  <input
                    type="email"
                    className="form-control form-control-lg mb-3"
                    placeholder="Email*"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <input
                    type="password"
                    className="form-control form-control-lg mb-3"
                    placeholder="Password*"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  {/* Terms and Condition */}
                  <div className="form-check d-flex justify-content-center mb-5 text-start">
                    <input className="form-check-input me-2" type="checkbox" required />
                    <label className="form-check-label">
                      I agree to all statements in <Link to="/TandC" className='tc'>Terms and Condition</Link>
                    </label>
                  </div>
                  <div className="d-flex justify-content-end pt-3">
                    <button type="submit" className="btn submit btn-lg ms-2">Register</button>
                  </div>
                  <p>Already have an account? <Link to="/login">Login here</Link></p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Signup;
