import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer4 from '../components/footer4';
import Navbar8 from '../components/navbar8';
import { db } from '../firebase';
import './Home.css';

const Person = () => {
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  const { userId } = useParams();

  useEffect(() => {
    if (!userId) {
        navigate('/Login');
        return;
      }
    const fetchProfile = async () => {
        const q = query(collection(db, "Mentors"), where("userId", "==", userId));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty ) {
            console.log("No profile found!");
        }
         else{       // Iterate through the querySnapshot to access the data
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());
          setProfile(doc.data()); // Update state with the profile data

        });
    }
      
    };

    fetchProfile();
  }, [navigate, userId]);

  const handleUpdateClick = () => {
    navigate(`/update/${profile?.userId}`); // Navigate to the update page
  };

  if (!profile) {
    return // Show loading state while fetching
  }

  return (
    <div className="person-page">
        <Navbar8 />
        <div className='col-xl-6 d-none d-xl-block '>
      <h1>Person Page</h1>
      <div className="profile-image ">
        <img src="https://images.unsplash.com/photo-1614383686443-6f7c99dcb690?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Profile" className="img-circle" />
      </div>
      <div className="profile-info">
        <h2>{profile.name}</h2>
        <p>{profile.description}</p>
        <div className="social-links">
          {profile.instagram && <a href={profile.instagram} target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a>}
          {profile.youtube && <a href={profile.youtube} target="_blank" rel="noopener noreferrer"><i className="bi bi-youtube"></i></a>}
          {profile.linkedin && <a href={profile.linkedin} target="_blank" rel="noopener noreferrer"><i className="bi bi-linkedin"></i></a>}
        </div>
        <button onClick={handleUpdateClick}>Update Profile</button>
      </div>
      </div>
      <Footer4 />
    </div>
  );
};

export default Person;
