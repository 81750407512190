import "./Mentors.css";
import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';

const Mentor_hero = () => {
  const [Mentors, setMentors] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMentors = async () => {
      const querySnapshot = await getDocs(collection(db, 'Mentors'));
      setMentors(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchMentors();
  }, []);

  const handleProfileClick = (userId) => {
    navigate(`/Mentors/${userId}`);
  };

  return (
    <div className="Mentors">
      <div className="page-title" data-aos="fade">
        <div className="heading">
          <div className="container">
            <div className="row d-flex justify-content-center text-center">
              <div className="col-lg-8">
                <h1>Trainers</h1>
                <h1 className="mb-0">Looking For Mentors, Influencers check these out?
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="trainers" className="section trainers">
        <div className="container">
          <div className="row gy-5 " >
          {Mentors.map(profile => (
              <div className="col-lg-4 col-md-6 member"  onClick={() => handleProfileClick(profile.userId)} data-aos="fade-up" data-aos-delay={profile.id * 100} key={profile.id}>
                <div className="member-img">
                  <img  src={profile.image} 
                         className="img-fluid" alt={profile.name} />
                  <div className="social">
                  {profile.instagram &&  <a href={profile.instaID} target="_blank" rel="noopener noreferrer">
                      <i className="bi bi-instagram"></i>
                    </a>}
                    {profile.instagram &&  <a href={profile.youtube} target="_blank" rel="noopener noreferrer">
                      <i className="bi bi-youtube"></i>
                    </a>}
                    {profile.instagram &&  <a href={profile.Linkedin} target="_blank" rel="noopener noreferrer">
                      <i className="bi bi-Linkedin"></i>
                    </a>}
                  </div>
                </div>
                <div className="member-info text-center">
                {profile.name ? <h4>{profile.name}</h4> :<h4> {profile.userName}</h4>}
                  {profile.domains ? <span>{profile.domains}</span>:<span>No domain to show</span>}
                  {profile.Descripstion ? <p>{profile.Descripstion}</p>:<p>no description available</p>}
                  {profile.city ? <p>{profile.city}</p>:<p>Unknown city</p>}
                  
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Mentor_hero;


// const Mentor_hero = (props) => {
//     const [trainers, setTrainers] = useState([]);

//     // Fetch trainers data from MongoDB
//     useEffect(() => {
//       const fetchData = async () => {
//         try {
//           const response = await axios.get('/api/trainers');  // Replace with your actual API endpoint
//           setTrainers(response.data);
//         } catch (error) {
//           console.error("Error fetching trainers data:", error);
//         }
//       };
  
//       fetchData();
//     }, []);