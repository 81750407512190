import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { jwtDecode } from 'jwt-decode'; // Correct import
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/navbar8';
import { db } from '../firebase';
import './Signup.css';

const Update = () => {
  const [name, setName] = useState({ firstName: '', lastName: '' });
  const [description, setDescription] = useState('');
  const [domains, setDomains] = useState([]);
  const [newDomain, setNewDomain] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [gender, setGender] = useState('');
  const [instagram, setInstagram] = useState('');
  const [youtube, setYoutube] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [profileimage, setProfileimage] = useState('');
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  const { userId } = useParams(); // Get userId from the URL params

  // Fetch existing profile data
  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/Login');
        return;
      }

      try {
        const decodedToken = jwtDecode(token);
        //console.log(decodedToken);

        // Check token expiration
        const isExpired = Date.now() >= decodedToken.exp * 1000;
        if (isExpired) {
          localStorage.removeItem('token');
          navigate('/Login'); // Redirect to Login if token is expired
          return;
        }

        // Query the Firestore database for the user profile using userId
        const profileQuery = query(collection(db, 'Mentors'), where('userId', '==', userId));
        const querySnapshot = await getDocs(profileQuery);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const profileData = doc.data();
            //console.log("Profile:", profileData);

            // Set profile data into the state
            setName({
              firstName: profileData.name.split(' ')[0],
              lastName: profileData.name.split(' ')[1] || ''
            });
            setDescription(profileData.description || "");
            setDomains(profileData.domains || []);
            setCity(profileData.city || "");
            setPincode(profileData.pincode || "");
            setGender(profileData.gender || "");
            setInstagram(profileData.instagram || "");
            setYoutube(profileData.youtube || "");
            setLinkedin(profileData.linkedin || "");
            setProfileimage(profileData.profileImage || "https://images.unsplash.com/photo-1614383686443-6f7c99dcb690?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
            setImages(profileData.images || []);
        });
        } else {
          //console.log("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
        alert(error.message);
      }
    };

    fetchProfile();
  }, [navigate, userId]); // userId is a URL parameter, so include it as a dependency

  // Add domain
  const addDomain = () => {
    if (newDomain.trim() && !domains.includes(newDomain)) {
      setDomains([...domains, newDomain]);
      setNewDomain('');
    }
  };

  // Remove domain
  const removeDomain = (domain) => {
    setDomains(domains.filter((d) => d !== domain));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Query the Firestore database to get the profile document using userId
      const profileQuery = query(collection(db, 'Mentors'), where('userId', '==', userId));
      const querySnapshot = await getDocs(profileQuery);

      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (docSnapshot) => {
          const profileDoc = doc(db, 'Mentors', userId); // Correctly reference the document

          // Update the document with the new profile data
          await updateDoc(profileDoc, {
            name: `${name.firstName} ${name.lastName}`,
            description: description,
            domains: domains,
            city: city,
            pincode: Number(pincode),
            gender: gender,
            instagram: instagram,
            youtube: youtube,
            linkedin: linkedin,
            profileimage: profileimage,
            images: images
          });

          alert("Profile updated successfully!");
          navigate(`/Mentors/${userId}`); // Redirect to the profile page after update
        });
      } else {
        //console.log("No such document to update!");
        alert("No such document to update!");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      alert(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>FlarEdge | Update Profile</title>
        <meta name="description" content="Update your profile information." />
      </Helmet>
      <Navbar />
      <section className="h-100 bg-dark">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100" >
            <div className="col">
              <div className="card card-registration my-4">
                <div className="card-body p-md-5  row  text-black">
                <div className="col-xl-6 d-none d-xl-block">
                    <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/img4.webp"
                      alt="Sample img"
                      className="img-fluid"
                      style={{ borderTopLeftRadius: '.25rem', borderBottomLeftRadius: '.25rem' }}
                    />
                  </div>
                  <div className="col-xl-6">
                  <h2 className="mb-3 text-start">Update your profile</h2>
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="First Name"
                        value={name.firstName}
                        onChange={(e) => setName({ ...name, firstName: e.target.value })}
                      />
                    </div>
                    <div className="col-md-6 mb-5">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Last Name"
                        value={name.lastName}
                        onChange={(e) => setName({ ...name, lastName: e.target.value })}
                      />
                    </div>
                  </div>

                  {/* Gender Selection */}
                  <div className="d-md-flex justify-content-start align-items-center mb-5 py-2">
                    <h6 className="mb-0 me-4 pe-2 text-start">Gender: </h6>
                    <div className="form-check form-check-inline mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        value="Female"
                        checked={gender === "Female"}
                        onChange={() => setGender("Female")}
                      />
                      <label className="form-check-label">Female</label>
                    </div>
                    <div className="form-check form-check-inline mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        value="Male"
                        checked={gender === "Male"}
                        onChange={() => setGender("Male")}
                      />
                      <label className="form-check-label">Male</label>
                    </div>
                    <div className="form-check form-check-inline mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        value="Others"
                        checked={gender === "Others"}
                        onChange={() => setGender("Others")}
                      />
                      <label className="form-check-label">Others</label>
                    </div>
                  </div>

                  {/* City and Pincode */}
                  <input
                    type="text"
                    className="form-control form-control-lg mb-3"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control form-control-lg mb-3"
                    placeholder="Pincode"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                  />

                  {/* Description */}
                  <textarea
                    className="form-control"
                    rows="3"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>

                 {/* Domain Input */}
                    <div className="input-group mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Add Domain"
                        value={newDomain}
                        onChange={(e) => setNewDomain(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && addDomain()}
                    />
                    <button className="btn btn-outline-secondary" type="submit" onClick={addDomain}>
                        Add
                    </button>
                    </div>
                    <ul className="list-group mb-1">
                    {domains.map((domain) => (
                        <li key={domain} className="list-group-item d-flex justify-content-between align-items-center">
                        {domain}
                        <button type="button" className="btn btn-danger btn-sm" onClick={() => removeDomain(domain)}>
                            x
                        </button>
                        </li>
                    ))}
                    </ul>


                  {/* Social Links */}
                  <input
                    type="text"
                    className="form-control form-control-lg mb-3 mt-3"
                    placeholder="Instagram"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control form-control-lg mb-3"
                    placeholder="YouTube"
                    value={youtube}
                    onChange={(e) => setYoutube(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control form-control-lg mb-3"
                    placeholder="LinkedIn"
                    value={linkedin}
                    onChange={(e) => setLinkedin(e.target.value)}
                  />

                  <button type="button" onClick={handleSubmit}  className="btn btn-primary btn-lg btn-block">Update Profile</button>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Update;
